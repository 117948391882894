
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StairsLoader from '@/components/StairsLoader.vue';
import Axios, { AxiosRequestConfig } from 'axios'

const SystemModule = namespace("system");

type SendingFormStatus = "loading" | "successed" | "error" | "yet";

// const axios = Axios.create({
//   baseURL: 'https://a6zmpjx2zb.execute-api.ap-northeast-1.amazonaws.com/default',
//   headers: {
//     'Content-Type': 'application/json'
//   },
//   responseType: 'json' 
// })

@Options({
  components: {
    StairsLoader
  },
})
export default class Contact extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  private isModalActive = false;

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  @SystemModule.Action("updateMenuState")
  private updateMenuState!: (state: boolean) => Promise<void>;

  @Watch("isModalActive")
  private switchModalState() {
    if (this.isModalActive) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    this.sendingFormStatus = "yet";
  }

  @Watch("sendingFormStatus")
  private onChangeSendingFormStatus() {
    if (this.sendingFormStatus === "successed") {
      this.postData.name = "";
      this.postData.email = "";
      this.postData.text = "";
    }
  }

  private sendingFormStatus: SendingFormStatus = "yet";

  private async sendForm() {
    this.sendingFormStatus = "loading";

    try {
      const request: AxiosRequestConfig = {
        url: "https://a6zmpjx2zb.execute-api.ap-northeast-1.amazonaws.com/default/api/contact",
        method:'post',
        data: {
          name: this.postData.name,
          email: this.postData.email,
          details: this.postData.text
        },
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: false
      }
      const result = await Axios(request);
      this.sendingFormStatus = "successed";
    } catch (error) {
      this.sendingFormStatus = "error";
    }
  }

  private postData = {
    name: "",
    email: "",
    text: "",
  };

  private get isFormValid() {
    return (
      this.postData.name.length > 0 &&
      this.postData.email.length > 0 &&
      this.postData.text.length > 0
    );
  }
}
